<template>
  <div class="col-12 card card-body">
    <div class="col-12 table-responsive">
        <div class="col-12 text-center g" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <div class="col-12 g col-lg-6 c">
            <div class="form-group">
              <label for="">نوع الرسائل</label>
              <select class="form-control" v-model="type" @change="getArchive()">
                <option :value="null">الكل</option>
                <option value="teachers">للمعلمين</option>
                <option value="students">للطلاب</option>
              </select>
            </div>
        </div>
        <div class="g col-12"><button @click="getArchive()" class="btn btn-sm btn-primary"><i class="fa fa-refresh"></i> تحديث</button> &nbsp; <button class="btn btn-sm btn-warning" @click="printDiv()"><i class="fa fa-print"></i> طباعة الصفحة</button></div>
        <table class="table table-sm table-hover table-bordered" id="headerTable">
            <thead>
                <th>
                    الجوال
                </th>
                <th>
                    التاريخ
                </th>
                <th>
                    الحالة
                </th>
                <th>
                    طريقة الارسال
                </th>
                <th>
                    الرسالة
                </th>
            </thead>
            <tbody>
                <tr v-for="message in messages" :key="message._id">
                    <td>
                        {{ message.phone }}
                    </td>
                    <td>
                        {{ message.date }}
                    </td>
                    <td>
                        <span v-if="message.status == 0" class='btn btn-sm btn-warning'>
                            <i class="fa fa-clock-o"></i>
                            جاري الارسال
                        </span>
                        <span v-if="message.status == 1" class='btn btn-sm btn-success'>
                            <i class="fa fa-check"></i>
                            تم الارسال
                        </span>
                        <span v-if="message.status == 2" class='btn btn-sm btn-danger'>
                            <i class="fa fa-times"></i>
                            فشل الارسال
                        </span>
                        <span v-if="message.status == 3" class='btn btn-sm btn-info'>
                            <i class="fa fa-whatsapp"></i>
                            مرسل بواسطة اداة الواتساب
                        </span>
                        <small v-if="message.status == 2">
                            <br>
                            {{ message.response }}
                        </small>
                    </td>
                    <td>
                        <span v-if="message.method == 'sms'"><i class="fa fa-mobile"></i> SMS ({{ JSON.parse(message.settings).sms_portal }})</span>
                        <span v-if="message.method == 'whatsapp-api'"><i class="fa fa-whatsapp"></i> الواتس الذكي</span>
                        <span v-if="message.method == 'whatsapp'"><i class="fa fa-whatsapp"></i> اداة الواتساب</span>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-secondary vv" v-if="!message.view_message" @click="message.view_message = true;"><i class='fa fa-eye'></i> عرض الرسالة</button>
                        <span v-if="message.view_message">{{ message.message }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
        <button class="btn btn-primary" @click="page++; getArchive()" v-if="messages.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            messages: [],
            page: 0,
            loading: false,
            school_id: localStorage.getItem("school_id"),
            type: null
        }
    },
    created(){
        this.getArchive();
    },
    methods: {
        printDiv() {
            $(".vv").each(function(){
                $(this).click()
            })
            setTimeout(() => {
                
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .btn-danger{
                    background: #FD8A8A;
                }
                .btn-success{
                    background: #97DECE;
                }
                .btn-info{
                    background: #82AAE3;
                }
                .btn{
                    width: 100% !important;
                    padding-left: 2px;
                    padding-right: 2px;
                }
                </style>`);
            newWin.document.close();
            newWin.print();
            newWin.close();
            }, 100);
        },
        getArchive(){
            if(!this.school_id || this.school_id == "undefined"){
                alert("عفواً لم يتم التعرف على حسابك لعرض الرسائل الخاصة به")
                return;
            }
            var g = this;
            this.loading = true;
            $.post(api + '/api/messages/archive', {
                school_id: g.school_id,
                page: this.page,
                type: this.type
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.messages = JSON.parse(JSON.stringify(response.response)).map(function(a){
                        a.view_message = false
                        return a
                    })
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        }
    }
}
</script>

<style>

</style>